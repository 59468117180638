<esd-theme-header (languageChange)="switchLang($event)" [currentLang]="currentLang" [(mobileMenuVisible)]="mobileMenue">
	@if(this.authService.isLoggedIn()) {
		@if(this.authService.userInfo().userData; as user) {
			<ng-template esdThemeMenuItem>
				<a routerLinkActive="active" routerLink="/home" translate>
					<mat-icon>home</mat-icon>
					<span class="show-mobile">
						&nbsp;
						{{'t_home' | translate}}
					</span>
				</a>
			</ng-template>
		
			@if(this.roleService.hasRoles([Roles.USER_MANAGEMENT, Roles.LOGIN_CONNECT], true) | async) {
				<ng-template esdThemeMenuItem>
					<a href="javascript:void(0)">{{'t_measdatamanagement' | translate}}</a>
					<div class="subMenueItem">
						<a routerLinkActive="active" routerLink="/measurement" translate>t_measurements</a>
						<a routerLinkActive="active" routerLink="/devicetest" translate>t_devicetests</a>

						@if(!user.isPrivate) {
							<a routerLinkActive="active" routerLink="/live-measurement" translate>t_live_measurement</a>
						}
					</div>
				</ng-template>
			}
		
			<ng-template esdThemeMenuItem>
				<a href="javascript:void(0)">{{'t_devicemanagement' | translate}}</a>
				<div class="subMenueItem">
					<a routerLinkActive="active" routerLink="/service/overview" translate>t_my_devices</a>
	
					@if(this.roleService.hasRoles([Roles.USER_MANAGEMENT, Roles.DEVICE_GROUP_ADMIN], true) | async) {
						<a routerLinkActive="active" routerLink="/settings/devicegroups" translate>t_my_groups</a>
					}
				</div>
			</ng-template>
		
			@if(!user.isPrivate) {
				<ng-template esdThemeMenuItem>
					<a href="javascript:void(0)">{{'t_orderings' | translate}}</a>
					<div class="subMenueItem">
						<a routerLinkActive="active" routerLink="/salesoffers" translate>t_my_offers</a>
						<a routerLinkActive="active" routerLink="/order/overview" translate>t_my_orders</a>
						@if(this.roleService.hasRoles([Roles.USER_MANAGEMENT, Roles.LOGIN_CONNECT], true) | async) {
							<a routerLinkActive="active" routerLink="/credit" translate>t_my_credit</a>
						}
					</div>
				</ng-template>

				<ng-template esdThemeMenuItem>
					<a href="javascript:void(0)">{{'t_settings' | translate}}</a>
					<div class="subMenueItem">
						<a routerLinkActive="active" routerLink="/settings/userconfig" translate>t_user_management</a>
					</div>
				</ng-template>
			}
		
			<ng-template esdThemeMenuItem>
				<a href="{{'links.connect_info' | translate}}">
					<mat-icon>
						account_circle
					</mat-icon>
					&nbsp;
	
					@if(this.authService.userInfo().userData; as userinfo) {
						{{userinfo.name}}
					}
				</a>
		
				<div class="subMenueItem">
					<a href="https://login.esders.de/profile">
						<mat-icon>
							badge
						</mat-icon>
						&nbsp;
						{{'t_profile' | translate}}
					</a>
		
					<a class="icon" href="javascript:void(0)" (click)="logout()">
						<mat-icon>
							power_settings_new
						</mat-icon>
						&nbsp;
						{{'t_logout' | translate}}
					</a>
				</div>
			</ng-template>
		
			<ng-template esdThemeMenuItem>
				<a href="{{'links.connect_info' | translate}}" trackLink="to-help">
					<mat-icon>
						help_outline
					</mat-icon>
					<span class="show-mobile">
						&nbsp;
						{{'t_support' | translate}}
					</span>
				</a>
			</ng-template>
		}
	}
</esd-theme-header >
<main>
	<router-outlet></router-outlet>
	<app-download-manager-popup></app-download-manager-popup>

	@if(this.authService.isLoggedIn()) {
		<app-shopping-card-fab></app-shopping-card-fab>
	}
</main>
<esd-theme-footer></esd-theme-footer>
