import { PlatformModule } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { AuthService, provideAuthentification } from '@esders/authentificate';
import { ShopApiModule, ShopConfiguration } from '@esders/shop-api';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthConfigModule } from './auth/auth-config.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MatPaginatorI18nService } from './_services/mat-paginator-i18n.service';
import { ConnectApiModule, ConnectConfiguration } from '@esders/connect-api';
import { DummyComponent } from './components/dummy.component';
import { TrackLinkDirective, provideAnalytics } from '@esders/analytics';
import { FooterComponent, HeaderComponent, MenuitemDirective } from "@esders/theme";
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/cs';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/pt-br';
import 'moment/locale/zh-cn';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

@NgModule({ declarations: [
        AppComponent,
        DummyComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [
		HeaderComponent,
        FooterComponent,
        MenuitemDirective,
		BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        PlatformModule,
        MatExpansionModule,
        MatIconModule,
        MatTableModule,
        MatSelectModule,
        MatCardModule,
        SharedModule,
        CoreModule,
        TrackLinkDirective,
        AuthConfigModule,
        ShopApiModule,
        ConnectApiModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        LeafletModule], providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nService,
        },
		provideAuthentification({
            logoPath: "assets/images/Esders-Connect-512.png",
            afterLoginRoute: "/home",
            authorityUrl: environment.authorityUrl,
            clientId: environment.clientId
        }),
		provideAnalytics({
			excludedRoutes: [/\/auth\/.*/g]
		}),
        {
            provide: ShopConfiguration,
            useFactory: (authService: AuthService) => new ShopConfiguration({
                basePath: environment.shoppingApiUri,
                credentials: { Bearer: authService.jwtToken.bind(authService) }
            }),
            deps: [AuthService],
            multi: false
        },
        {
            provide: ConnectConfiguration,
            useFactory: (authService: AuthService) => new ConnectConfiguration({
                basePath: environment.connectGwUri,
                credentials: { Bearer: authService.jwtToken.bind(authService) },
            }),
            deps: [AuthService],
            multi: false
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        DatePipe,
        CookieService,
		provideCharts(withDefaultRegisterables()),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "assets/i18n/");
}