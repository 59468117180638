@use '@angular/material' as mat;

// @function _patch-error-palette($palette) {
//     @return map.merge(
//         $palette,
//         (
//         error: (
//             0: #000000,
//             10: #410002,
//             20: #690005,
//             25: #7e0007,
//             30: #93000a,
//             35: #a80710,
//             40: #ba1a1a,
//             50: #de3730,
//             60: #ff5449,
//             70: #ff897d,
//             80: #ffb4ab,
//             90: #ffdad6,
//             95: #ffedea,
//             98: #fff8f7,
//             99: #fffbff,
//             100: #ffffff,
//         ),
//         )
//     );
// }

$md-esders-palette: (
    50 : #fce0e4,
    100 : #f6b3ba,
    200 : #f1808d,
    300 : #eb4d5f,
    400 : #e6263c,
    500 : #e2001a,
    600 : #df0017,
    700 : #da0013,
    800 : #d6000f,
    900 : #cf0008,
    A100 : #fff6f7,
    A200 : #ffc3c4,
    A400 : #ff9092,
    A700 : #ff7778,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// html {
//     @include mat.theme((
//         color: (
//             primary: $md-esders-palette,
//             tertiary: $md-esders-palette,
//             theme-type: light
//         ),
//         typography: 'Noto Sans, Noto Sans SC, sans-serif',
//         density: 0
//     ));
// }

@include mat.core();

$esders-app-primary: mat.m2-define-palette($md-esders-palette);
$esders-app-accent: mat.m2-define-palette($md-esders-palette);
$esders-app-warn: mat.m2-define-palette($md-esders-palette);
$font-family: 'Noto Sans, Noto Sans SC, sans-serif';

$esders-app-theme: mat.m2-define-light-theme((
	color: (
		primary: $esders-app-primary,
		accent: $esders-app-accent,
		warn: $esders-app-warn,
	),
	typography: mat.m2-define-typography-config(
		$font-family: $font-family, 
		$button: mat.m2-define-typography-level(
			$font-family: $font-family,
			$font-size: 16px
		)
	),
	density: 0,
));

.mdc-button {
	color: #000000 !important;

	.mat-icon {
		width: 24px !important;
		height: 24px !important;
		font-size: 24px !important;
	}
}

.mat-mdc-header-cell {
	color: #0000008a !important;
}

@include mat.all-component-themes($esders-app-theme);