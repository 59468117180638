@use "@esders/theme" as theme;

.mobil-icon{
   position: relative;
   top: 4px;
}

.icon {
	display: flex !important;
	align-items: center;
}

.show-mobile {
	display: none;

	@media #{theme.$medium-and-down} {
		display: block;
	}
}