@use "variablen";

@use "sass:list";
@mixin col-factory($thisPrefix) {
    @for $i from 1 through variablen.$grid-columns {
        .#{$thisPrefix}#{$i} {
            flex-basis: calc(100% / variablen.$grid-columns * $i);
            max-width: calc(100% / variablen.$grid-columns * $i);
        }
        .#{$thisPrefix}-offset-#{$i} {
            margin-left: calc(100% / variablen.$grid-columns * $i) !important;
        }
    }
    .row.start-#{$thisPrefix} {
        justify-content: flex-start;
    }
    .row.center-#{$thisPrefix} {
        justify-content: center;
    }
    .row.end-#{$thisPrefix} {
        justify-content: flex-end;
    }
    .row.top-#{$thisPrefix} {
        align-items: flex-start;
    }
    .row.middle-#{$thisPrefix} {
        align-items: center;
    }
    .row.bottom-#{$thisPrefix} {
        align-items: flex-end;
    }
    .row.around-#{$thisPrefix} {
        justify-content: space-around;
    }
    .row.between-#{$thisPrefix} {
        justify-content: space-between;
    }
    .first-#{$thisPrefix} {
        order: -1;
    }
    .last-#{$thisPrefix} {
        order: 1;
    }
}

.row {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
}
.row.reverse {
    flex-direction: row-reverse;
}
.col {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: variablen.$gutter;
}
.col.reverse {
    flex-direction: column-reverse;
}
.col .row{
    width: calc(100% + #{variablen.$gutter} + #{variablen.$gutter});
    margin: -(variablen.$gutter);
}
.col.row{
    padding: 0;
    margin: 0;
}

@for $i from 1 through list.length(variablen.$breakpoint-up-prefixes) {
    $thisPrefix: list.nth(variablen.$breakpoint-up-prefixes, $i);
    @if $thisPrefix == "s" {
        @include col-factory($thisPrefix);
    } @else if $thisPrefix == "m" {
        @media #{variablen.$medium-and-up} {
            @include col-factory($thisPrefix);
        }
    } @else if $thisPrefix == "l" {
        @media #{variablen.$large-and-up} {
            @include col-factory($thisPrefix);
        }
    } @else if $thisPrefix == "xl" {
        @media #{variablen.$extra-large-and-up} {
            @include col-factory($thisPrefix);
        }
    }
}
