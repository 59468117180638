@use "sass:color";
// ==========================================================================
// SCSS variables
// ==========================================================================
//
//
// Color
// ==========================================================================
$text-color: #444444;
$background-color: #efefef;
$primary-color: #e2001a !default;
$primary-color-light: color.adjust($primary-color, $lightness: 15%) !default;
$primary-color-dark: color.adjust($primary-color, $lightness: -15%) !default;

$menue-color: #ffffff;
$footer-color: #333333;
$button-color: #444444;
$button-color-disabled: color.adjust($button-color, $lightness: 15%) !default;

$state-send-color: #4caf50;
$state-part-send-color: #ff9800;
$state-open-color: #aaaaaa;

//
// Screen sizes
// ==========================================================================
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

//
// Grid config
// ==========================================================================
$grid-columns: 12 !default;
$breakpoint-up-prefixes: ("s", "m", "l", "xl");
$gutter: 1rem !default;

// Hauptmenue
// ==========================================================================

$menue-color1: #efefef;
$menue-color1-dark: color.adjust($menue-color1, $lightness: -15%) !default;
$menue-color2: white;